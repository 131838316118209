<template>
  <b-sidebar
    :fullheight="true"
    :fullwidth="false"
    :overlay="true"
    :right="false"
    v-model="open"
    class="sidebar-container"
  >
    <div class="p-1">
      <div class="px-5 py-5">
        <img src="@/assets/helpharma.png" alt="helpharma logo" />
      </div>
      <b-menu>
        <div class="user-name-container py-2 mb-2 mr-4">
          <p><b-icon icon="account-circle"></b-icon> {{ userName }}</p>
        </div>
        <b-menu-list>
          <b-menu-item
            icon="home"
            label="Inicio"
            class="menu-option"
            @click="navigate('Home')"
          ></b-menu-item>
        </b-menu-list>

        <b-menu-list
          label="Clinimetrias"
          v-if="OptionsAllowedUser(clinimetriasAllMenuOptions()).length"
        >
          <b-menu-item
            v-for="(option, index) in OptionsAllowedUser(
              clinimetriasAllMenuOptions()
            )"
            :key="index"
            :icon="option.icon"
            :class="option.class"
            :label="option.label"
            @click="option.route ? navigate(option.route) : ''"
          >
            <b-menu-item
              v-for="(children, index) in option.children"
              :key="index"
              :label="children.label"
              class="menu-sub-option"
              @click="navigate(children.route)"
            ></b-menu-item>
          </b-menu-item>
        </b-menu-list>

        <b-menu-list
          label="Administración"
          v-if="OptionsAllowedUser(administrationAllMenuOptions()).length"
        >
          <b-menu-item
            v-for="(option, index) in OptionsAllowedUser(
              administrationAllMenuOptions()
            )"
            :key="index"
            :icon="option.icon"
            :class="option.class"
            :label="option.label"
            @click="option.route ? navigate(option.route) : ''"
          >
            <b-menu-item
              v-for="(children, index) in option.children"
              :key="index"
              :label="children.label"
              class="menu-sub-option"
              @click="navigate(children.route)"
            ></b-menu-item>
          </b-menu-item>
        </b-menu-list>

        <b-menu-list label="Acciones">
          <b-menu-item
            icon="eye"
            label="Cambiar contraseña"
            class="menu-option"
            @click="redirectSecurty"
          ></b-menu-item>

          <b-menu-item
            icon="logout"
            label="Salir"
            class="menu-option"
            @click="logOut"
          ></b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
  </b-sidebar>
</template>

<script>
import { permissions } from "../../enum/applicationPermissions";
import defineAbilityFor from "../../config/rules";

export default {
  data() {
    return {
      open: false,
      temporalSurvey: {},
      userName: "",
    };
  },
  created() {
    defineAbilityFor();

    this.$global.$on("userUpdateData", () => {
      this.userName = JSON.parse(localStorage.user).name;
    });

    this.$global.$on("showSidebar", () => {
      this.open = !this.open;
    });

    this.userName = this.$store.state.user.userData?.name;
  },
  methods: {
    clinimetriasAllMenuOptions() {
      return [
        {
          label: "Psoriasis",
          icon: "playlist-edit",
          class: "menu-option",
          accessPermission: "",
          children: [
            {
              label: "Indice de la severidad del área de Psoriasis - PASI",
              route: "PasiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_PASI),
            },
            {
              label: "Dermatology life quality index – DLQI",
              route: "DlqiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_DLQI),
            },
            {
              label: "Children's dermatology life quality index – CDLQI",
              route: "CdlqiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_CDLQI),
            },
            {
              label: "Infants' dermatology life quality index – IDQoL",
              route: "IdqolList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_IDQOL),
            },
            {
              label: "Area de superficie corporal - BSA",
              route: "BsaList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_BSA),
            },
            {
              label: "Nail Psoriasis Severity Index - NAPSI",
              route: "NapsiList",
              accessPermission: this.$ability.can(
                permissions.SEE_SURVEYS_NAPSI
              ),
            },
            {
              label: "Nail Psoriasis Severity Index(MM) - NAPSI MM",
              route: "NapsimmList",
              accessPermission: this.$ability.can(
                permissions.SEE_SURVEYS_NAPSIMM
              ),
            },
            {
              label: "Cuestionario – PGA",
              route: "PgaList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_PGA),
            },
            {
              label:
                "Toronto Psoriatic Arthritis Screening Questionnaire - TOPAS",
              route: "TopasList",
              accessPermission: this.$ability.can(
                permissions.SEE_SURVEYS_TOPAS
              ),
            },
          ],
        },
        {
          label: "Dermatitis",
          icon: "playlist-edit",
          class: "menu-option",
          accessPermission: false,
          children: [
            {
              label: "Dermatology life quality index – DLQI",
              route: "DlqiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_DLQI),
            },
            {
              label: "Children's dermatology life quality index – CDLQI",
              route: "CdlqiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_CDLQI),
            },
            {
              label: "Infants' dermatology life quality index – IDQoL",
              route: "IdqolList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_IDQOL),
            },
            {
              label: "Dermatitis Atopica EASI",
              route: "EasiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_EASI),
            },
            {
              label: "Medida de eczema orientada al paciente – POEM",
              route: "PoemList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_POEM),
            },
            {
              label: "European task force on atopic dermatitis - SCORAD",
              route: "ScoradList",
              accessPermission: this.$ability.can(
                permissions.SEE_SURVEYS_SCORAD
              ),
            },
            {
              label: "Atopic dermatitis control tool - ADCT",
              route: "AdctList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_ADCT),
            },
            {
              label: "Numerical rating scale - NRS",
              route: "NrsList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_NRS),
            },
          ],
        },
        {
          label: "Hidradenitis Supurativa",
          icon: "playlist-edit",
          class: "menu-option",
          accessPermission: false,
          children: [
            {
              label: "Dermatology life quality index – DLQI",
              route: "DlqiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_DLQI),
            },
            {
              label: "Children's dermatology life quality index – CDLQI",
              route: "CdlqiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_CDLQI),
            },
            {
              label: "Infants' dermatology life quality index – IDQoL",
              route: "IdqolList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_IDQOL),
            },
            {
              label: "Cuestionario – HURLEY",
              route: "HurleyList",
              accessPermission: this.$ability.can(
                permissions.SEE_SURVEYS_HURLEY
              ),
            },
            {
              label: "Cuestionario – HIS4",
              route: "His4List",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_HIS4),
            },
          ],
        },
        {
          label: "Urticaria",
          icon: "playlist-edit",
          class: "menu-option",
          accessPermission: false,
          children: [
            {
              label: "Dermatology life quality index – DLQI",
              route: "DlqiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_DLQI),
            },
            {
              label: "Children's dermatology life quality index – CDLQI",
              route: "CdlqiList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_CDLQI),
            },
            {
              label: "Infants' dermatology life quality index – IDQoL",
              route: "IdqolList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_IDQOL),
            },
            {
              label: "Urticaria Activity Score - UAS7",
              route: "Uas7List",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_UAS7),
            },
            {
              label: "Puntuación de la actividad de la urticaria – UCT",
              route: "UctList",
              accessPermission: this.$ability.can(permissions.SEE_SURVEYS_UCT),
            },
          ],
        },
        {
          label: "Escala de calidad de vida – WHOQOL-BREF ECV",
          icon: "playlist-edit",
          class: "menu-option",
          accessPermission: this.$ability.can(
            permissions.SEE_SURVEYS_WHOQOL_BREF
          ),
          route: "WhoqlList",
        },
        {
          label: "Riesgo Asociado",
          icon: "playlist-edit",
          class: "menu-option",
          accessPermission: this.$ability.can(
            permissions.SEE_SURVEYS_ASSOCIATED_RISK
          ),
          route: "Riesgo-asociadoList",
        },
      ];
    },
    administrationAllMenuOptions() {
      return [
        {
          label: "Gestion de pacientes",
          icon: "playlist-edit",
          class: "menu-option",
          accessPermission: this.$ability.can(permissions.SEE_PATIENTS),
          route: "patients",
        },
        {
          label: "Reportes",
          icon: "playlist-edit",
          class: "menu-option",
          accessPermission: false,
          children: [
            {
              label: "Clinimetrias simple",
              route: "report",
              accessPermission: this.$ability.can(
                permissions.SEE_SIMPLE_REPORT
              ),
            },
            {
              label: "Clinimetrias detallado",
              route: "detailed-report",
              accessPermission: this.$ability.can(
                permissions.SEE_DETAILED_REPORT
              ),
            },
          ],
        },
      ];
    },
    OptionsAllowedUser(menuOptions) {
      // `this` points to the vm instance
      return menuOptions
        .filter((option) =>
          option.children
            ? option.children.some((child) => child.accessPermission === true)
            : option.accessPermission
        )
        .map((option) => {
          const children = option.children?.filter(
            (child) => child.accessPermission
          );

          return {
            ...option,
            children: children,
          };
        });
    },
    async logOut() {
      this.changeLoaderStatus(true);
      await this.$authService
        .logoutSecurity()
        .then(() => {
          localStorage.clear();
          this.$store.dispatch("patient/setPatientData", null);
          this.$store.dispatch("token/saveUserToken", null);
          this.$store.dispatch("user/setUserId", null);
          this.$store.dispatch("user/setUserData", null);

          this.$router.push({ name: "login" });
          defineAbilityFor();
        })
        .finally(() => {
          this.changeLoaderStatus(false);
        });
    },
    navigate(route) {
      this.$router.push({ name: route }).catch(() => {});
      this.open = !this.open;
      this.scrollTop();
    },
    redirectSecurty() {
      window.location.href = `${
        process.env.VUE_APP_SECURITY_FRONT
      }/forgot-password?urlFrom=${encodeURIComponent(window.location.href)}`;
    },
  },
};
</script>

<style scoped>
.user-name-container {
  font-weight: bold;
  color: black;
  text-align: center;
}

.menu-option {
  font-weight: bold;
}

.menu-sub-option {
  font-weight: 400;
}
</style>
