<template>
  <div>
    <navbar></navbar>
    <sidebar></sidebar>
    <router-view class="px-5"></router-view>
  </div>
</template>

<script>
import Navbar from "./Navbar.vue";
import Sidebar from "./Sidebar.vue";

export default {
  components: { Navbar, Sidebar },
};
</script>

<style></style>
