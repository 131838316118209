<template>
  <b-navbar class="main-nav" :fixed-top="true" :transparent="true">
    <template slot="brand">
      <div class="menu-display pl-3 py-4" @click="showSidebar">
        <b-icon class="mr-1" icon="menu"></b-icon>
      </div>
      <b-navbar-item class="main-title">
        <img src="@/assets/helpharma.png" alt="helpharma" />
        <strong class="ml-4 ">Clinimetrias</strong>
      </b-navbar-item>
      <b-navbar-item class="nav-item" @click="goHome">
        <b-icon class="mr-1" icon="home"></b-icon>
        <strong> Inicio</strong></b-navbar-item
      >
    </template>
    <template slot="start"> </template>

    <template slot="end">
      <b-navbar-item>
        <b-field>
          <b-input
            class="search-patient"
            placeholder="Buscar paciente..."
            rounded
            type="search"
            icon-right="magnify"
            icon-right-clickable
            v-model="search"
            v-mask="'##########'"
            @keyup.native.enter="validate"
            @icon-right-click="validate"
            @input="resetFilter"
          ></b-input>
        </b-field>
      </b-navbar-item>
      <b-navbar-item class="nav-item">
        {{ userName }} <b-icon class="ml-2" icon="account-circle"></b-icon>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      langs: [
        { id: 1, code: "en", name: "English" },
        { id: 2, code: "es", name: "Español" },
      ],
      lang: { id: 2, code: "es", name: "Español" },
      userName: "",
      search: "",
    };
  },
  created() {
    this.$global.$on("userUpdateData", () => {
      this.userName = this.$store.state.user.userData?.name;
    });

    this.$global.$on("setPatientInput", (patientId) => {
      this.search = patientId;
    });

    this.$global.$on("patientDataDeleted", () => {
      this.search = "";
    });

    this.$global.$on("registerCancel", () => {
      this.search = "";
    });

    this.$store.dispatch(
      "user/setUserId",
      this.$store.state.user.userData
        ? (this.userName = this.$store.state.user.userData?.name)
        : null
    );
  },
  watch: {
    lang() {
      this.$i18n.locale = this.lang.code;
    },
  },
  methods: {
    logOut() {
      localStorage.clear();
      this.$store.dispatch("patient/setPatientData", null);
      this.$store.dispatch("user/userData", null);
      this.$store.dispatch("user/setUserId", null);
      this.$router.push({ name: "login" });
    },
    showSidebar() {
      this.$global.$emit("showSidebar");
    },
    validate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.emmitSearch();
      } else {
        if (!this.$v.search.required) {
          this.showToast(
            "Debes ingresar la cédula del paciente.",
            "is-warning",
            3000
          );
        } else if (!this.$v.search.minLength) {
          this.showToast("Formato de cédula incorrecto.", "is-warning", 3000);
        }
      }
    },
    goHome() {
      this.$router.push({ name: "Home" }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
      this.scrollTop();
    },
    emmitSearch() {
      this.$global.$emit("patientSearch", this.search);
    },
    resetFilter() {
      if (!this.search) {
        this.changeLoaderStatus(true);
        this.$store.dispatch("patient/setPatientData", null);
        setTimeout(() => {
          this.$global.$emit("patientDataDeleted");
          this.changeLoaderStatus(false);
        }, 1000);
      }
    },
  },
  validations: {
    search: {
      required,
      minLength: minLength(3),
    },
  },
};
</script>

<style scoped>
.main-nav {
  background-color: #ffffff !important;
}
.main-title {
  color: var(--dark-blue-color);
  cursor: inherit;
}

.main-title:hover {
  color: var(--light-blue-color);
}

.nav-item {
  color: var(--dark-blue-color);
}

.menu-display {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .nav-item {
    display: flex;
  }

  .nav-item > div {
    display: flex;
  }

  .nav-item > div > .pass-text {
    margin-left: 0.5rem;
  }

  .main-title {
    display: grid;
  }
}
</style>
